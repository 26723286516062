<template>
  <div class="a">
    <div class="scrollBox">
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_7.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_6.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_5.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_4.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_5.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_6.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_7.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_4.jpg" alt="" />
      </div>
      <div class="img-box">
        <img src="../assets/images/AboutUs_picture_5.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Space",
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style>
.a {
  width: 1400px;
  margin: 10%;
  height: 100vh;
  /* 弹性布局 水平垂直居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
body {
  margin-top: 10%;
  /* 100%窗口高度 */
}
.scrollBox {
  width: 1400px;
  height: 550px;
  /* 弹性布局 水平排列 */
  display: flex;
  flex-direction: row;
  /* 设置元素的倒影效果，below是倒影在元素下方，15px是元素和倒影的距离，后面的属性是设置倒影渐变 */
  -webkit-box-reflect: below 15px -webkit-linear-gradient(transparent 50%, rgba(255, 255, 255, 0.3));
}
.img-box {
  width: 100px;
  height: 550px;
  overflow: hidden;
  /* 加个动画过渡 */
  transition: 0.3s;
}
.img-box img {
  width: 100%;
  height: 100%;
  /* 由于这里我的图片都是竖图，而容器是横向的，所以对图片做了以下处理，这里大家根据自己的图片进行调节即可 */
  /* 对图片进行剪切，保留原始比例 */
  object-fit: cover;
  /* object-position属性一般与object-fit一起使用，用来设置元素的位置 */
  object-position: 50% 20%;
}
/* 默认最后一张展开 */
.img-box:nth-child(9) {
  width: 400px;
}
/* 鼠标移入，图片展开 */
.img-box:hover {
  width: 400px;
}
.img-box:hover ~ .img-box:nth-child(9) {
  width: 200px;
}
</style>
